import React from "react";

import Layout from "../../components/layout";
import Header from "../../components/header";
import Tabs from "../../components/tabsWhoWeAre";

const Group = () => {
  return (
    <Layout>
      <Header />
      <Tabs selection="grupo" />
      <main className="group">
        <div>
          <h2>Centro Distribuidor del Norte</h2>
          <p>
            El Centro Distribuidor del Norte, S.A. es una empresa de paquetería
            participada en su totalidad por Cenfarte y que nos permite dar una
            respuesta diaria a la distribución de medicamentos en la zona norte
            del país con tarifas y márgenes de entrega muy competitivos.
          </p>
        </div>
        <div>
          <h2>Datos de contacto</h2>
          <ul>
            <li>
              <span>Teléfono</span>
              <br />
              <span>942 334 609</span>
            </li>
            <li>
              <span>Fax</span>
              <br />
              <span>942 334 670</span>
            </li>
            <li>
              <span>Correo electrónico</span>
              <br />
              <span>cenfarte@cenfarte.com</span>
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  );
};

export default Group;
